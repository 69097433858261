import { template as template_327a30e669e14657b61ea0a409c3ed21 } from "@ember/template-compiler";
const FKText = template_327a30e669e14657b61ea0a409c3ed21(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
