import { template as template_f48b3483e085432481d50a907f6db956 } from "@ember/template-compiler";
const FKLabel = template_f48b3483e085432481d50a907f6db956(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
